import React, {Component} from "react"
import {BrowserRouter, Route, Switch} from "react-router-dom"
import App from "./App"
import ErrorPage from "./Components/ErrorPage"

// https://www.youtube.com/watch?v=91F8reC8kvo
class Router extends Component {

    render(){
        return(
            <BrowserRouter>
                <Switch>
                    <Route path={"/"} component={App} exact/>
                    <Route component={ErrorPage}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;