import React, {Component} from 'react';
import "../resources/Styles.css"

class ErrorPage extends Component {
    render() {
        return (
            <div className={"App-container"}>
                <h2 style={{color:"white"}}>Error: This path does not exists.</h2>
            </div>
        );
    }
}

export default ErrorPage;
