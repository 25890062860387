import React, {Component} from 'react';
import "../resources/Styles.css"

// Icons
import { FaFacebookSquare, FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";


class MidBox extends Component {
    render() {
        return (
                <div className={"mid-box"}>
                    <div className={"message"}>
                        <p>Hello, I'm <span id={"name"}>Kristoffer Gjerde</span></p>
                        <p>Computer Science student at NTNU</p>
                    </div>
                    <div className={"links"}>
                        <a href={"https://www.facebook.com/kgjerde5"}> <FaFacebookSquare className={"icon"}/> </a>
                        <a href={"mailto:kristoffergjerde@gmail.com"}> <FaEnvelope className={"icon"}/> </a>
                        <a href={"https://www.linkedin.com/in/kristoffergjerde/"}> <FaLinkedin className={"icon"}/> </a>
                        <a href={"https://github.com/kristogj"}> <FaGithub className={"icon"}/> </a>
                    </div>
                </div>
        );
    }
}


export default MidBox;
