import React, {Component} from 'react';
import ParticleComponent from "./Components/ParticleComponent";
import "./resources/Styles.css"

import MidBox from "./Components/MidBox";
// import MediaBlockList from "./Components/MediaBlockList";


class App extends Component {
    render() {
        return (
            <div className={"App-container"}>
                <div className={"Top-container"}>
                    <ParticleComponent/>
                    <MidBox/>
                </div>
            </div>
        );
    }
}

export default App;
